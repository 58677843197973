import React, { Fragment } from 'react'
import {
  GetHandleProps,
  GetRailProps,
  GetTrackProps,
} from 'react-compound-slider'

import { colors } from '../../constants'

const railOuterStyle = {
  position: 'absolute',
  width: '100%',
  height: 12,
  transform: 'translate(0%, -50%)',
  cursor: 'pointer',
}

const railInnerStyle = {
  position: 'absolute',
  width: '100%',
  height: 4,
  transform: 'translate(0%, -50%)',
  pointerEvents: 'none',
  backgroundColor: colors.grey,
} as React.CSSProperties

export const SliderRail = ({
  getRailProps,
}: {
  getRailProps: GetRailProps
}) => (
  <Fragment>
    <div style={railOuterStyle} {...getRailProps()} />
    <div style={railInnerStyle} />
  </Fragment>
)

type HandleProps = {
  domain: number[]
  handle: {
    id: string
    value: number
    percent: number
  }
  getHandleProps: GetHandleProps
}

export const Handle = ({
  domain: [min, max],
  handle: { id, value, percent },
  getHandleProps,
}: HandleProps) => (
  <Fragment>
    <div
      style={{
        left: `${percent}%`,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        WebkitTapHighlightColor: 'rgba(0,0,0,0)',
        zIndex: 5,
        width: 12,
        height: 12,
        cursor: 'pointer',
        backgroundColor: 'none',
      }}
      {...getHandleProps(id)}
    />
    <div
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      style={{
        left: `${percent}%`,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        zIndex: 2,
        width: 12,
        height: 12,
        borderRadius: '50%',
        backgroundColor: colors.orange,
      }}
    />
  </Fragment>
)

type TrackProps = {
  source: {
    percent: number
  }
  target: {
    percent: number
  }
  getTrackProps: GetTrackProps
}

export const Track = ({ source, target, getTrackProps }: TrackProps) => (
  <div
    style={{
      position: 'absolute',
      transform: 'translate(0%, -50%)',
      height: 4,
      zIndex: 1,
      backgroundColor: colors.greyDark,
      cursor: 'pointer',
      left: `${source.percent}%`,
      width: `${target.percent - source.percent}%`,
    }}
    {...getTrackProps()}
  />
)
