import React from 'react'

import { colors } from '../constants'

const Social = () => (
  <>
    <p style={styles.contact}>Contact Sunset Radio</p>
    <div style={styles.social}>
      <a
        href="https://www.facebook.com/SunsetRadio.Live/"
        target="_blank"
        rel="noreferrer"
        style={styles.socialButton}>
        <img src={'/images/facebook.svg'} alt="Facebook" />
      </a>
      <a
        href="https://twitter.com/SunsetRadioLive"
        target="_blank"
        rel="noreferrer"
        style={styles.socialButton}>
        <img src={'/images/twitter.svg'} alt="Twitter" />
      </a>
      <a
        href="mailto:hello@sunsetradio.live"
        target="_blank"
        rel="noreferrer"
        style={{ ...styles.socialButton, marginRight: 0 }}>
        <img src={'/images/mail.svg'} alt="Mail" />
      </a>
    </div>
  </>
)

export default React.memo(Social)

const styles = {
  contact: {
    margin: '0 0 12px',
    opacity: 0.6,
    fontSize: 18,
    color: colors.white,
  },
  social: {
    display: 'flex',
    justifyContent: 'center',
  },
  socialButton: {
    marginRight: 24,
    width: 30,
    height: 30,
  },
}
