import React from 'react'

import { IS_SHORTCUT, colors, sizes } from '../constants'

import Social from './Social'
import Icon from './Icon'

const IS_POPUP =
  new URL(window.location.href).searchParams.get('popup') || IS_SHORTCUT

type Props = {
  onShowPopup: () => void
}

const Banner = ({ onShowPopup }: Props) => (
  <div className="banner" style={styles.banner}>
    <div style={styles.wrapper}>
      <div style={styles.title} />
      <h2 style={styles.subtitle}>Wherever You Are... Music comes first!</h2>
    </div>
    <div style={styles.wrapper} className="social-banner">
      <Social />
    </div>
    {!IS_POPUP && (
      <div className="toggle-popup" style={styles.popup} onClick={onShowPopup}>
        <Icon name="filter_none" />
        <p style={styles.popupLabel}>Pop-Up player</p>
      </div>
    )}
  </div>
)

export default React.memo(Banner)

const styles = {
  banner: {
    display: 'flex',
    justifyContent: 'space-between',
    height: sizes.banner,
    backgroundImage: `url('${process.env.PUBLIC_URL}/images/sunrise.jpg')`,
    backgroundColor: colors.black,
    backgroundPosition: '0 center',
    backgroundSize: 'auto 100%',
    backgroundRepeat: 'no-repeat',
    padding: '0 35px',
  },
  title: {
    height: 46,
  },
  subtitle: {
    fontSize: '20px',
    color: colors.white,
    lineHeight: '25px',
    fontStyle: 'italic',
    fontWeight: 300,
    margin: '0 18px 0',
  },
  wrapper: {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  popup: {
    cursor: 'pointer',
    color: colors.white,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.8,
  },
  popupLabel: {
    margin: '8px 0 0',
    fontSize: 14,
    textAlign: 'center',
  },
} as { [key: string]: React.CSSProperties }
