import React from 'react'

import { colors } from '../../constants'

import Thumbnail from '../Thumbnail'

import './style.css'
import { Track } from '../../types'

type Props = {
  tracks?: Track[]
}

const Tracks = ({ tracks }: Props) => (
  <div className="tracksWrapper">
    <p className="description">Last Played</p>
    <div>
      {tracks?.map(({ title, album, artist, localtime }) => (
        <div className="trackWrapper" key={title}>
          <p className="localtime">{localtime}</p>
          <div className="infoWrapper">
            <div
              className="separator"
              style={{ backgroundColor: colors.greyLight }}
            />
            <div
              className="arrow"
              style={{
                borderColor: `transparent transparent transparent ${colors.greyLight}`,
              }}
            />
            <Thumbnail
              artist={artist}
              album={album}
              size={64}
              style={{ marginLeft: 12 }}
            />
            <div className="info">
              <p className="title">{title}</p>
              <p className="secondary">
                <span className="label">by </span>
                {artist}
              </p>
              <p className="secondary">
                <span className="label">Album </span>
                {album}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
)

export default React.memo(Tracks)
