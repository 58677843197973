import React from 'react'

import Thumbnail from './Thumbnail'
import { Track } from '../types'

type Props = {
  track?: Track
}

const TrackInfo = ({ track }: Props) => (
  <div style={styles.infoWrapper}>
    <Thumbnail artist={track?.artist} album={track?.album} />
    <div style={styles.info} className="track-info">
      <p className="description" style={styles.description}>
        Now Playing
      </p>
      <p className="title" style={styles.title}>
        {track?.title}
      </p>
      <p className="artist" style={styles.secondary}>
        <span style={styles.label}>by </span>
        {track?.artist}
      </p>
      <p className="album" style={styles.secondary}>
        <span style={styles.label}>Album </span>
        {track?.album}
      </p>
      <div style={styles.qualityHelper}>
        <p style={styles.quality}>
          <img src={'/images/hd.svg'} alt="quality" style={styles.hd} />
          320 kbps
        </p>
      </div>
    </div>
  </div>
)

export default React.memo(TrackInfo)

const styles = {
  infoWrapper: {
    display: 'flex',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 24,
  },
  description: {
    display: 'none',
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '30px',
    margin: 0,
  },
  label: {
    fontWeight: 400,
    fontStyle: 'italic',
  },
  secondary: {
    margin: 0,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '26px',
  },
  qualityHelper: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
  },
  quality: {
    fontWeight: 500,
    fontStyle: 'italic',
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    margin: '12px 0 4px',
  },
  hd: {
    marginRight: 12,
  },
} as { [key: string]: React.CSSProperties }
