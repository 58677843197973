import React from 'react'
import axios from 'axios'
import { Analytics } from '@vercel/analytics/react'

import Player from '../components/Player'
import Tracks from '../components/Tracks'
import TrackInfo from '../components/TrackInfo'
import Banner from '../components/Banner'
import Social from '../components/Social'
import { IS_SHORTCUT, POPUP_URL, colors } from '../constants'

import './style.css'
import useQuery from '../utils/useQuery'
import { Track } from '../types'

const API =
  'https://whsh4u-clients.com:2199/external/rpc.php?m=recenttracks.get&username=cuntwjvs'
const IS_EMBEDDED =
  new URL(window.location.href).searchParams.get('embed') || IS_SHORTCUT

const App = () => {
  const playerRef = React.useRef<HTMLAudioElement | null>()

  const {
    response: { data: tracks },
    query,
  } = useQuery(async () => {
    const response = await axios.get(API)

    const rawTracks = (response.data.data[0] as Track[]).map(
      ({ artist, title, ...track }) => ({
        title: artist,
        artist: title,
        ...track,
      }),
    )

    return { data: rawTracks }
  }, [])

  const currentTrack = tracks?.[0]
  const previousTracks = tracks?.slice(1)

  React.useEffect(() => {
    const intervalId = setInterval(query, 300000) // 5min

    return () => {
      clearInterval(intervalId)
    }
  }, [query])

  const onShowPopup = React.useCallback(() => {
    window.open(POPUP_URL, ' ', 'height=520,width=992')
    playerRef.current?.pause()
  }, [])

  const onRef = React.useCallback((ref: HTMLAudioElement) => {
    playerRef.current = ref
  }, [])

  return (
    <div className={IS_EMBEDDED ? 'embed' : ''}>
      <div className="sunset-player">
        <Banner onShowPopup={onShowPopup} />
        <div className="wrapper" style={styles.wrapper}>
          <div className="showcase">
            <p className="description">Now Playing</p>
            <TrackInfo track={currentTrack} />
            <Player
              onRef={onRef}
              className="player"
              src="https://whsh4u-clients.com/proxy/cuntwjvs?mp=/stream"
            />
          </div>
          <Tracks tracks={previousTracks} />
        </div>
        <div className="social" style={{ backgroundColor: colors.black }}>
          <Social />
        </div>
      </div>
      <Analytics />
    </div>
  )
}

export default App

const styles = {
  wrapper: {
    background: `url('/images/background.png')`,
  },
}
