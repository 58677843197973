import React from 'react'
import { ClipLoader } from 'react-spinners'

import { colors } from '../constants'

import Icon from './Icon'

type Props = {
  play?: boolean
  loading?: boolean
  onClick?: () => void
}

const PlayButton = ({ play, loading, onClick }: Props) => {
  const onButtonClick = React.useCallback(() => {
    if (play && loading) {
      return
    }

    onClick?.()
  }, [play, loading, onClick])

  return (
    <div style={styles.button} onClick={onButtonClick}>
      {play && loading ? (
        <ClipLoader size={20} color={colors.greyLight} loading={true} />
      ) : (
        <Icon style={styles.icon} name={play ? 'pause' : 'play_arrow'} />
      )}
    </div>
  )
}

export default React.memo(PlayButton)

const styles = {
  button: {
    height: 40,
    width: 40,
    borderRadius: '50%',
    background: colors.orange,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  icon: {
    color: colors.greyLighter,
  },
} as { [key: string]: React.CSSProperties }
