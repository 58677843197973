export const colors = {
  greyLighter: '#EFEDE7',
  greyLight: '#CBCBCB',
  grey: '#AEAEAE',
  greyDark: '#646464',
  orange: '#AD9623',
  black: '#000000',
  white: '#FFFFFF',
}

export const sizes = {
  banner: 116,
}

export const POPUP_URL = 'https://sunset-radio.now.sh?popup=true&autoplay=true'

export const IS_SHORTCUT =
  (window.navigator as any).standalone ||
  window.matchMedia('(display-mode: standalone)').matches
