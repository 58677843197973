import React from 'react'

type QueryResult = any

type UseQuery<T extends QueryResult> = {
  response: Partial<T>
  loading: boolean
  error: string
  query: (...args: any) => Promise<T>
}

type UseQueryOptions = {
  skip?: boolean
  lazy?: boolean
}

const useQuery = <T extends QueryResult>(
  asyncFn: (...args: any) => Promise<T>,
  deps: any[],
  { lazy, skip }: UseQueryOptions = {},
): UseQuery<T> => {
  const [loading, setLoading] = React.useState(false)
  const [response, setresponse] = React.useState<T>({} as T)
  const [error, setError] = React.useState<any>(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizeFn = React.useCallback(asyncFn, deps)

  const query = React.useCallback(
    async (...args: any) => {
      try {
        setLoading(true)

        const res = (await memoizeFn(...args)) as T & { error?: any }

        if (res.error) throw res.error

        setresponse(res)
        setLoading(false)

        return res as T
      } catch (e) {
        setLoading(false)
        setError(e)

        return { error: e } as T
      }
    },
    [memoizeFn],
  )

  React.useEffect(() => {
    if (lazy || skip) return

    query()
  }, [lazy, query, skip])

  return { response, loading, error, query }
}

export default useQuery
