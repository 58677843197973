import React from 'react'

type Props = {
  style?: React.CSSProperties
  name: string
  onClick?: () => void
}

const Icon = ({ style, name, onClick }: Props) => (
  <i
    className="material-icons"
    style={{ ...styles.icon, ...style }}
    onClick={onClick}>
    {name}
  </i>
)

export default React.memo(Icon)

const styles = {
  icon: {
    userSelect: 'none',
  },
} as { [key: string]: React.CSSProperties }
