import React from 'react'
import { ClipLoader } from 'react-spinners'

import { colors } from '../constants'
import useQuery from '../utils/useQuery'
import axios from 'axios'

type Props = {
  artist?: string
  album?: string
  size?: number
  style?: React.CSSProperties
}

const Thumbnail = ({ artist, album, size, style }: Props) => {
  const {
    response: { url },
  } = useQuery(async () => {
    try {
      if (!artist || !album) {
        throw new Error('No info')
      }

      const response = await axios.get<{
        album: { image: { '#text': string }[] }
      }>(
        `https://ws.audioscrobbler.com/2.0/?method=album.getinfo&api_key=7e23cdbdef92d9ae6911d5a5ec048352&artist=${encodeURIComponent(
          artist,
        )}&album=${encodeURIComponent(album)}&format=json`,
      )

      const url = response.data.album.image.slice(-1)[0]['#text']

      if (!url) {
        throw new Error('No thumbnail url')
      }

      return { url }
    } catch (error) {
      return { url: '/images/placeholder.png' }
    }
  }, [artist, album])

  const thumbnailStyle = React.useMemo(
    () => ({
      ...styles.thumbnailWrapper,
      ...(size
        ? { minWidth: size, maxWidth: size, width: size, height: size }
        : {}),
      ...style,
    }),
    [style, size],
  )

  return (
    <div
      style={{
        ...styles.thumbnailWrapper,
        ...thumbnailStyle,
        ...style,
      }}
      className="thumbnail">
      {!url ? (
        <ClipLoader color={colors.orange} loading={true} />
      ) : (
        <img src={url} alt="Album Cover" style={styles.image} />
      )}
    </div>
  )
}
export default React.memo(Thumbnail)

const styles = {
  thumbnailWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
  },
}
