import React from 'react'

import { colors } from '../constants'

import PlayButton from './PlayButton'
import Volume from './Volume'

const INITIAL_VOLUME = 50
const AUTOPLAY = Boolean(
  new URL(window.location.href).searchParams.get('autoplay'),
)

type Props = {
  className?: string
  src?: string
  onRef?: (ref: HTMLAudioElement) => void
}

const Player = ({ className, src, onRef }: Props) => {
  const playerRef = React.useRef<HTMLAudioElement>()
  const [loading, setLoading] = React.useState(true)
  const [play, setPlay] = React.useState(!!AUTOPLAY)

  const onPlayerRef = React.useCallback(
    (ref: HTMLAudioElement | null) => {
      if (!ref) {
        return
      }

      onRef?.(ref)
      playerRef.current = ref
    },
    [onRef],
  )

  React.useEffect(() => {
    if (!playerRef.current) return

    function onCanPlay() {
      setLoading(false)
    }

    playerRef.current.addEventListener('canplay', onCanPlay)

    playerRef.current.volume = INITIAL_VOLUME / 100
  }, [play])

  React.useEffect(() => {
    if (play) {
      playerRef.current?.play()
    } else {
      playerRef.current?.pause()
    }
  }, [play])

  const togglePlay = React.useCallback(() => {
    setPlay((prev) => !prev)
  }, [])

  const onVolumeChange = React.useCallback((volume: number) => {
    if (!playerRef.current) return

    playerRef.current.volume = volume / 100
  }, [])

  return (
    <div className={className} style={styles.player}>
      <div style={styles.holder}>
        <div style={styles.holderBrackground} />
        <div style={styles.wrapper} className="play-button">
          <PlayButton loading={loading} play={play} onClick={togglePlay} />
        </div>

        <Volume onUpdate={onVolumeChange} />
        <audio ref={onPlayerRef} controls style={styles.audio}>
          <source src={src} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </div>
    </div>
  )
}

export default React.memo(Player)

const styles = {
  player: {
    display: 'flex',
    padding: '6px 0',
  },
  holder: {
    height: 28,
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    position: 'relative',
  },
  holderBrackground: {
    position: 'absolute',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    background: colors.greyLighter,
    mixBlendMode: 'multiply',
  },
  audio: {
    display: 'none',
  },
  volume: {
    cursor: 'pointer',
    fontSize: '32px',
  },
  volumeSvg: {
    cursor: 'pointer',
    width: '44px',
    height: '44px',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
    zIndex: 9,
  },
} as { [key: string]: React.CSSProperties }
