import React from 'react'
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider'

import Icon from '../Icon'
import { SliderRail, Handle, Track } from './utils'
import { ReactComponent as MuteIcon } from './mute.svg'

const sliderStyle = {
  position: 'relative',
  width: '100%',
  touchAction: 'none',
  marginRight: 24,
  marginLeft: 14,
}

const DOMAIN = [0, 100]
const INITIAL_VOLUME = 50

type Props = {
  onUpdate?: (volume: number) => void
}

const Volume = ({ onUpdate }: Props) => {
  const volumeRef = React.useRef(INITIAL_VOLUME)
  const [isMuted, setIsMuted] = React.useState(false)
  const [, forceRefresh] = React.useState(false)

  const onVolumeUpdate = React.useCallback(
    (values: readonly number[]) => {
      const volume = values[0]
      volumeRef.current = volume
      onUpdate?.(volume)

      setIsMuted(volume === 0)
    },
    [onUpdate],
  )

  const toggleVolume = React.useCallback(() => {
    setIsMuted((prev) => !prev)
  }, [])

  React.useEffect(() => {
    if (isMuted) {
      onUpdate?.(0)
    } else {
      volumeRef.current = volumeRef.current || INITIAL_VOLUME
      onUpdate?.(volumeRef.current)
      forceRefresh((prev) => !prev)
    }
  }, [isMuted, onUpdate])

  return (
    <div style={styles.wrapper}>
      {isMuted ? (
        <MuteIcon style={styles.volumeSvg} onClick={toggleVolume} />
      ) : (
        <Icon style={styles.volume} onClick={toggleVolume} name="volume_down" />
      )}
      <Slider
        mode={1}
        step={1}
        domain={DOMAIN}
        rootStyle={sliderStyle}
        onUpdate={onVolumeUpdate}
        values={isMuted ? [0] : [volumeRef.current]}>
        <Rail>
          {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map((handle) => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={DOMAIN}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
    </div>
  )
}

const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
    zIndex: 9,
  },
} as { [key: string]: React.CSSProperties }

export default React.memo(Volume)
